<template>
  <div class="events" v-if="GET_EVENTS">
    <div v-if="GET_EVENTS && GET_EVENTS.length" class="grid">
      <event-card
        v-for="event in GET_EVENTS"
        :key="event.id"
        :event="event"
        @click.native="goToEvent(event)"
      />
    </div>

    <div v-else class="events-plug">
      Ничего не найдено
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Tag from "@/components/Parts/Tag";
import EventCard from "@/components/Parts/EventCard";
import BannerGuestCard from "@/components/Parts/BannerGuestCard";

export default {
  name: "Events",

  components: {
    EventCard,
    Tag,
    BannerGuestCard
  },

  computed: {
    ...mapGetters(['GET_EVENTS',]),
  },

  methods: {
    goToEvent(event) {
      this.$router.push({ path: `/event/${event.id}` });
      this.$store.dispatch("setSelectedEvent", event);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";
.tags {
  position: absolute;
  bottom: 160px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.events {
  .grid {
    margin: 0 32px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .event {
      margin-bottom: 84px;
    }
  }

  .events-plug {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1052px;
    font-size: 56px;
  }
}
</style>
